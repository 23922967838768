import request from "./request";

/* AUTHENTICATION API */
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* CONTACT US API */
export const getContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/contactUs`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getContactUsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editContactUs = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/contactUs/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteContactUs = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* WAITING LIST APIS */
export const getWaitingList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/waitingList`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getWaitingListById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/waitingList/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editWaitingList = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/waitingList/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteWaitingList = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/waitingList/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
